export const commonData = {

	appName: 'CyberCourse application',
	siteUrl() {
		if (process.env.VUE_APP_MODE === 'production') {
			return 'https://cybercourse.kz/'
		}
		return 'http://localost:8080'
	},
	baseApiEndpoint() {
		if (process.env.VUE_APP_MODE === 'production') {
			return 'https://backend.cybercourse.kz/web'
		}
		return 'http://localhost/basic/web'
	},
	uploadsPath() {
		if (process.env.VUE_APP_MODE === 'production') {
			return 'https://backend.cybercourse.kz/uploads'
		}
		return 'http://localhost/basic/uploads'
	},
}