<template>
	<div class="wrapper">
		<router-view></router-view>
	</div>
</template>
<script>
export default {
	name: "App",
	data: () => ({
	}),
	methods: {
		checkLastPage(page) {
			switch (page) {
				case 'courses':
					this.$router.push('/courses')
					return
				case 'news':
					this.$router.push('/news')
					return
				default:
					this.$router.push('/')
					return
			}
		},
		openModal() {
			this.closeMenu()
			document.body.style.overflow = 'hidden'
			this.visibleModal = true
		},
		goToPage() {
			let page = localStorage.getItem('page')
			window.scrollTo({
				top: 0,
			});
			if (!page) {
				this.$router.push('/')
			} else {
				this.checkLastPage(page)
			}
		},
	},

	async created() {
		// Создаем новый элемент link с новой иконкой
		const newIconLink = document.createElement('link');
		newIconLink.rel = 'icon';
		newIconLink.type = 'image/png';
		newIconLink.href = '/logo.png';

		const existingIconLink = document.querySelector('link[rel="icon"]');

		if (existingIconLink) {
			document.head.removeChild(existingIconLink);
		}
		document.head.appendChild(newIconLink);
		this.goToPage()
	},
}
</script>
<style lang="scss" scoped>
@import url('./style/css/variables.css');
@import url('./style/css/reset.css');
@import url('./style/css/common.css');
@import url('./style/css/modules/header.css');

</style>