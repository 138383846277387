
import home_page from '@/components/pages/home_page'
import courses_page from '@/components/pages/courses_page.vue'
import news_page from '@/components/pages/news_page.vue'
import info_page from '@/components/modal/info_page.vue'


export default [
	{
		path: '/',
		component: home_page,
		meta: {
			title: "Кибер Курс",
		},
		beforeEnter: (to, from, next) => {
			document.title = to.meta.title || "Cyber Course";
			localStorage.setItem('page', 'home')
			window.scrollTo({
				top: 0,
				left: 0,
				// behavior: 'smooth'
			});
			next();
		},
	},
	{
		path: '/courses',
		component: courses_page,
		meta: {
			title: "Наши курсы - Кибер Курс",
			description: "У нас есть различные курсы, такие как: PHP, Python, C++, Web-вёрстка на html&css, SQL, Frontend-разработчик на Vue 3 и Backend-разработчик на Yii2"
		},
		beforeEnter: (to, from, next) => {
			const metaDescriptionTag = document.querySelector('meta[name="description"]');
			if (metaDescriptionTag) {
				metaDescriptionTag.setAttribute('content', to.meta.description || '');
			}
			document.title = to.meta.title || "Cyber Course";
			localStorage.setItem('page', 'courses')
			window.scrollTo({
				top: 0,
				left: 0,
				// behavior: 'smooth'
			});
			next();
		},
	},
	{
		path: '/news',
		component: news_page,
		meta: {
			title: "Новости - Кибер Курс",
			description: "Новости из мира IT. Исскуственный интеллект, виртуальная реальность и многое другое."
		},
		beforeEnter: (to, from, next) => {
			const metaDescriptionTag = document.querySelector('meta[name="description"]');
			if (metaDescriptionTag) {
				metaDescriptionTag.setAttribute('content', to.meta.description || '');
			}
			document.title = to.meta.title || "Cyber Course";
			localStorage.setItem('page', 'news')
			window.scrollTo({
				top: 0,
				left: 0,
				// behavior: 'smooth'
			});
			next();
		},
	},
	{
		path: '/info/:id/:course',
		component: info_page,
		meta: {
			title: "Информация о курсе",
			description: "Подробная информация о курсе, заработная плата, популярность и примеры."
		},
		beforeEnter: (to, from, next) => {
			const metaDescriptionTag = document.querySelector('meta[name="description"]');
			if (metaDescriptionTag) {
				metaDescriptionTag.setAttribute('content', to.meta.description || '');
			}
			document.title = to.meta.title || "Cyber Course";
			localStorage.setItem('page', 'courses')
			window.scrollTo({
				top: 0,
				left: 0,
				// behavior: 'smooth'
			});
			next();
		},
	},
];
