// store.js
import { createStore } from 'vuex';
import httpClient from "../services/http.service";

function calculate_step(number) {
	let step = 0;
	if (number < 500) {
		step = 3;
	} else if (number < 1000) {
		step = 25;
	} else {
		let power_of_10 = String(number).length - 1;
		step = 25 * (10 ** (power_of_10 % 3));
	}
	return step;
}

const store = createStore({
	state: () => ({
		courses: [],
		favorites: [],
		categories: [],
		numbers: [120, 987, 1564],
	}),
	mutations: {
		SET_COURSES(state, data) {
			state.courses = data;
		},
		updateNumber(state, payload) {
			state.numbers[payload.index] = payload.value;
		},
		SET_CATEGORIES(state, data) {
			state.categories = data;
		},
		addToFavorites(state, courseId) {
			state.favorites.push(courseId);
		},
		removeFromFavorites(state, courseId) {
			const index = state.favorites.indexOf(courseId);
			if (index !== -1) {
				state.favorites.splice(index, 1);
			}
		},
		saveFavoritesToLocalStorage(state) {
			localStorage.setItem('favorites', JSON.stringify(state.favorites));
		},
		restoreFavoritesFromLocalStorage(state) {
			const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
			state.favorites = favorites;
		},
	},
	getters: {
		isCourseFavorite: (state) => (courseId) => {
			return state.favorites.includes(courseId);
		},
		getNumbers(state) {
			return state.numbers;
		},
	},
	actions: {
		showNumberAnim({ commit, state }) {
			const intervals = [];

			state.numbers.forEach((element, key) => {
				let step = calculate_step(element);
				let t = 20;
				let num = 0;

				intervals[key] = new Promise(resolve => {
					const interval = setInterval(() => {
						num += step;
						if (num > element) {
							num = element;
							clearInterval(interval);
							resolve();
						}
						commit('updateNumber', { index: key, value: num });
					}, t);
				});
			});

			Promise.all(intervals).then(() => {
				// Все интервалы завершены, можно выполнить дополнительные действия
				console.log('succsess');
			});
		},

		async GET_COURSES({ commit }) {
			const { data } = await httpClient.get('courses/get-all-courses');
			commit('SET_COURSES', data);
			commit('restoreFavoritesFromLocalStorage');
		},
		async GET_CATEGORIES({ commit }) {
			const { data } = await httpClient.get('courses/get-all-categories');
			commit('SET_CATEGORIES', data);
		},
		addToFavorites({ commit }, courseId) {
			commit('addToFavorites', courseId);
			commit('saveFavoritesToLocalStorage');
		},
		removeFromFavorites({ commit }, courseId) {
			commit('removeFromFavorites', courseId);
			commit('saveFavoritesToLocalStorage');
		},
	},
});

export default store;
