const animateService = {
	scrollToSection(index, sections) {
		const animationDuration = 750;
		const frames = animationDuration / 6.25;
		const section = sections[index];
		const startScrollTop = window.scrollY;
		const targetScrollTop = section.offsetTop;
		const scrollStep = (targetScrollTop - startScrollTop) / frames;
		let currentFrame = 0;

		function animateScroll() {
			currentFrame++;
			const newScrollTop = startScrollTop + scrollStep * currentFrame;
			window.scrollTo(0, newScrollTop);

			if (currentFrame < frames) {
				requestAnimationFrame(animateScroll);
			} else {
				window.scrollTo(0, targetScrollTop);
			}
		}

		animateScroll();
	},
	isTouchscreen() {
		return ("ontouchstart" in window) || (navigator.maxTouchPoints > 0) ? true : false;
	},
	offset(el) {
		const rect = el.getBoundingClientRect(),
			scrollLeft = window.scrollX || document.documentElement.scrollLeft,
			scrollTop = window.scrollY || document.documentElement.scrollTop
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
	},

	animOnScroll(animItems) {
		for (let index = 0; index < animItems.length; index++) {
			const animItem = animItems[index];
			const animItemHeight = animItem.offsetHeight;
			const animItemOffset = this.offset(animItem).top;
			const animStart = 4;
			let animItemPoint = window.innerHeight - animItemHeight / animStart;


			const btn_top = document.getElementById('btn-top')
			btn_top.onclick = function () {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			}

			const scrollY = window.scrollY || document.documentElement.scrollTop

			scrollY > 400 ? btn_top.classList.add('showing') : btn_top.classList.remove('showing')


			if (animItemHeight > window.innerHeight) {
				animItemPoint = window.innerHeight - window.innerHeight / animStart;
			}

			if (
				scrollY > animItemOffset - animItemPoint &&
				scrollY < animItemOffset + animItemHeight
			) {

				setTimeout(() => {
					animItem.classList.add('_active');
				}, 150);
			}
			else {
				if (!animItem.classList.contains('_no-repeat')) {
					animItem.classList.remove('_active');
				}
			}
		}
	},
}


export default animateService;